<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="disabled"
    content-class="zg-select-border-radius"
  >
    <template v-slot:activator="{ on }">
      <v-layout column class="mb-2">
        <v-text-field
          v-on="on"
          :label="label"
          :placeholder="placeholder"
          :solo="solo"
          :outlined="!solo"
          flat
          v-model="date"
          v-mask="'####-##-##'"
          :rules="nonRequiredDate"
          :class="['test', !flat && 'default-input-style', !noMargins && 'mx-4']"
          :hide-details="hideDetails"
          :disabled="disabled"
          :readonly="!$vuetify.breakpoint.smAndUp"
          clear-icon="mdi-close"
        >
          <template v-slot:append>
            <AutosaveIcon v-if="autosave" :value="status"></AutosaveIcon>
          </template>
        </v-text-field>
      </v-layout>
    </template>
    <v-date-picker
      :first-day-of-week="1"
      color="primary"
      no-title
      v-model="date"
      @input="show = false"
      locale="pl-pl"
      class="zg-default-picker-button shadow"
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
      :disabled="disabled"
      :allowed-dates="allowedDates"
      :picker-date.sync="pickerFormat"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import Validators from "@/mixins/Validators";
import ModificationMixin from "@/mixins/ModificationMixin";
import moment from "moment";

export default {
  mixins: [Validators, ModificationMixin],
  props: {
    value: {},
    label: {},
    min: {
      default: null,
    },
    max: {
      default: null,
    },
    placeholder: {
      type: String,
      default: "YYYY-MM-DD",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    noMargins: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      pickerDate: this.min
        ? this.min
        : this.max
        ? this.max
        : this.value
        ? this.value
        : moment().format("YYYY-MM-DD"),
    };
  },
  watch: {
    min() {
      if (!this.date && this.min) {
        this.pickerDate = this.min;
      }
    },
    value() {
      if (this.date) {
        this.pickerDate = this.date;
      }
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        if (value == "") {
          value = null;
        }
        this.$emit("update:value", value);
      },
    },
    pickerFormat: {
      get() {
        if (!this.pickerDate) {
          return moment().format("YYYY-MM-DD");
        } else if (moment(this.pickerDate, "YYYY", true).isValid()) {
          return this.pickerDate + "-01-01";
        } else if (moment(this.pickerDate, "YYYY-MM", true).isValid()) {
          return this.pickerDate + "-01";
        } else if (moment(this.pickerDate, "YYYY-MM-", true).isValid()) {
          return this.pickerDate + "01";
        } else if (
          this.pickerDate.length == 9 &&
          moment(this.pickerDate, "YYYY-MM-D", true).isValid()
        ) {
          return this.pickerDate.slice(0, -1) + "01";
        } else if (moment(this.pickerDate, "YYYY-MM-DD", true).isValid()) {
          return this.pickerDate;
        } else {
          return moment().format("YYYY-MM-DD");
        }
      },
      set(value) {
        this.pickerDate = value;
      },
    },
  },
  methods: {
    allowedDates(date) {
      if (this.max !== null) {
        return moment(date) <= moment(this.max);
      } else if (this.min !== null) {
        return moment(date) >= moment(this.min);
      } else {
        return true;
      }
    },
    showPicker() {
      this.show = true;
    },
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("date");
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .zg-default-picker-button .v-date-picker-header .v-btn {
  color: black !important;
  height: 30px !important;
  width: 30px !important;

  &.shadow {
    border-radius: 50% !important;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  }
}
::v-deep .zg-default-picker-button
  .v-date-picker-header
  .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
  button {
  text-transform: capitalize !important;
  font-weight: normal !important;
}
</style>